$bgColor: #000;
$mainColor: #9E949C;
$secColor: #F5EFF3;

@font-face{
	font-family: EditorialNew;
	src: url("../fonts/PPEditorialNew-Ultralight.woff2")
}

// GLOBAL RESPONSIVE

@media screen and (max-width: 600px) {
	body {
        h1{
            font-family: EditorialNew;
            font-style: normal;
            color: $mainColor;
            font-size: 52px;
            letter-spacing: -2px;
            font-weight: 100;
            line-height: 1;
        }
        h5{
            font-size: 16px;
            font-weight: 200;
            font-family: "Helvetica Neue";
            font-style: normal;
            // line-height: 20px;
            color: $secColor;
        }
        .arrow_link{
            height: 15%;
        }
    }
    .menu_close{
        // width: 55px;
        .menu_title{
            .menu_title-text{
                display: none !important;
            }
        }
        .menu_footer{
            display: none;
        }
    }
    .menu_open{
        width: 75vw !important;
        .menu_footer{
            margin-bottom: 12vh;
        }
    }


}
@media screen and (max-aspect-ratio: 9/14) {
	body {
        h1{
            font-family: EditorialNew;
            font-style: normal;
            color: $mainColor;
            font-size: 52px;
            letter-spacing: -2px;
            font-weight: 100;
            line-height: 1;
        }
        h5{
            font-size: 16px;
            font-weight: 200;
            font-family: "Helvetica Neue";
            font-style: normal;
            // line-height: 20px;
            color: $secColor;
        }
        .horizontal_blank1{
            height: 10vh;
        }
    }
    .menu_close{
        // width: 55px;
        .menu_title{
            .menu_title-text{
                display: none !important;
            }
        }
        .menu_footer{
            display: none;
        }
    }
    .menu.menu_open{
        width: 75vw !important;
        .menu_top{
            .menu_items {
                a.menu_item{
                    margin-bottom: 3vh;
                }
            }
        }
    }
}