@font-face {
  font-family: EditorialNew;
  src: url("../fonts/PPEditorialNew-Ultralight.woff2");
}
@media screen and (max-width: 600px) {
  body h1 {
    font-family: EditorialNew;
    font-style: normal;
    color: #9E949C;
    font-size: 52px;
    letter-spacing: -2px;
    font-weight: 100;
    line-height: 1;
  }
  body h5 {
    font-size: 16px;
    font-weight: 200;
    font-family: "Helvetica Neue";
    font-style: normal;
    color: #F5EFF3;
  }
  body .arrow_link {
    height: 15%;
  }
  .menu_close .menu_title .menu_title-text {
    display: none !important;
  }
  .menu_close .menu_footer {
    display: none;
  }
  .menu_open {
    width: 75vw !important;
  }
  .menu_open .menu_footer {
    margin-bottom: 12vh;
  }
}
@media screen and (max-aspect-ratio: 9/14) {
  body h1 {
    font-family: EditorialNew;
    font-style: normal;
    color: #9E949C;
    font-size: 52px;
    letter-spacing: -2px;
    font-weight: 100;
    line-height: 1;
  }
  body h5 {
    font-size: 16px;
    font-weight: 200;
    font-family: "Helvetica Neue";
    font-style: normal;
    color: #F5EFF3;
  }
  body .horizontal_blank1 {
    height: 10vh;
  }
  .menu_close .menu_title .menu_title-text {
    display: none !important;
  }
  .menu_close .menu_footer {
    display: none;
  }
  .menu.menu_open {
    width: 75vw !important;
  }
  .menu.menu_open .menu_top .menu_items a.menu_item {
    margin-bottom: 3vh;
  }
}/*# sourceMappingURL=responsive.css.map */